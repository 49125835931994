
import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/home.css';

const Home = () => {
  return (
    <div className="container d-flex flex-column align-items-center justify-content-center vh-100">
      <h1 className="mb-4 text-center">Welcome to FnB Management</h1>
      <p className="mb-4 text-center">
        Manage your Food and Beverage operations with ease.
      </p>
      <div>
        <Link to="/login" className="btn btn-primary btn-lg mb-2">
          Login
        </Link>
        <br />
        {/* <Link to="/forgot-password" className="text-primary">
          Forgot Password?
        </Link> */}
      </div>
    </div>
  );
};

export default Home;